/** @format */

import styled from '@emotion/styled';
import React from 'react';

interface DashboardProps {
  children: any;
}

const StyledDashboard = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100%;
  grid-gap: 8px;
  overflow: hidden;
  //padding: 8px;
  box-sizing: border-box;
`;

function DashboardLayout(props: DashboardProps) {
  return <StyledDashboard>{props.children}</StyledDashboard>;
}

export default DashboardLayout;

/** @format */

import LoadingSpinner from '@atoms/LoadingSpinner';
import Scrollbar from '@atoms/Scrollbar';
import { css } from '@emotion/react';
import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';

interface InfiniteScrollListProps {
  dataLength: number;
  id: string;
  hasMore: boolean;
  fetchNext(): void;
  children: ReactNode;
}

const StyledInfiniteScrollList = styled.div`
  height: 230px;
`;

function InfiniteScrollList(props: InfiniteScrollListProps) {
  const scrollbarId = `${props.id}scrollBar`;

  return (
    <StyledInfiniteScrollList>
      <Scrollbar id={scrollbarId}>
        <InfiniteScroll
          dataLength={props.dataLength}
          hasMore={props.hasMore}
          scrollableTarget={scrollbarId}
          next={() => {
            props.fetchNext();
          }}
          loader={
            <div
              css={css`
                height: 20px;
                margin-block: 4px;
              `}
            >
              <LoadingSpinner />
            </div>
          }
        >
          {!props.dataLength && !props.hasMore && <FormattedMessage defaultMessage={'No Results Found'} description={'Notification that a search did not find any results'} />}
          {props.children}
        </InfiniteScroll>
      </Scrollbar>
    </StyledInfiniteScrollList>
  );
}

export default InfiniteScrollList;

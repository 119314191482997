/** @format */

import EntityTag from '@atoms/EntityTag';
import FlexBox from '@atoms/FlexBox';
import { cssVars } from '@atoms/GlobalStyles';
import InfiniteScrollList from '@atoms/InfiniteScrollList';
import Input from '@atoms/Input';
import PrefixedInput from '@atoms/PrefixedInput';
import { Tag } from '@common/model/apiClient/Tag';
import { TagCategory } from '@common/model/apiClient/TagCategory';
import { Icon, IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { SetStateAction } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export type TagWithCategory = Tag & { category: TagCategory };

interface TagMenuProps {
  tags: Array<TagWithCategory>;
  search: string;
  setSearch: React.Dispatch<SetStateAction<string>>;
  fetchNext(): void;
  id: string;
  hasMore: boolean;
  icon: IconName;
  onSelect(tag: TagWithCategory): void;
}

const StyledTagMenu = styled.div`
  border: 1px solid ${cssVars.lightGrey};
  border-radius: 3px;
  padding: 4px;
  background: ${cssVars.white};
`;

function TagMenu(props: TagMenuProps) {
  return (
    <StyledTagMenu>
      <PrefixedInput icon={<FontAwesomeIcon icon={['far', 'search']} />}>
        <Input onChange={(value) => props.setSearch(value)} value={props.search} />
      </PrefixedInput>
      <InfiniteScrollList id={props.id} fetchNext={props.fetchNext} hasMore={props.hasMore} dataLength={props.tags.length}>
        {props.tags.map((tag) => (
          <EntityTag
            category={tag.category?.name}
            style={tag.category.style}
            key={tag.id + tag.label}
            label={tag.label}
            css={css`
              //width: 95%;
              margin: 4px;
            `}
            button={props.icon}
            onClick={() => {
              props.onSelect(tag);
            }}
          />
        ))}
      </InfiniteScrollList>
    </StyledTagMenu>
  );
}

export default TagMenu;

/** @format */

import FlexBox from '@atoms/FlexBox';
import { cssVars } from '@atoms/GlobalStyles';
import { CurrencyContext } from '@common/application/context/CurrencyProvider';
import { CompanyClient } from '@common/model/apiClient';
import { Account } from '@common/model/apiClient/Account';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import DashboardList from '@organisms/Dashboard/DashboardList';
import { useRouter } from 'next/router';
import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { ICurrency } from '@common/application/context/CurrencyProvider/CurrencyProvider';
import { css } from '@emotion/react';
import Link from '@atoms/Link';

interface CompanyLeaderboardProps {
  filterRange: { start: Date; end: Date };
}

const StyledCompanyLeaderboard = styled.div`
  width: 100%;
  background: ${cssVars.white};
  //padding: 8px;
  border-radius: 3px;
  height: 100%;
  //overflow: hidden;
`;

const Card = styled.div`
  padding: 8px;
  cursor: pointer;
  background: ${cssVars.white};
  border-bottom: 1px solid ${cssVars.lightGrey};
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: background 0.3s ease;
  :hover {
    background: ${cssVars.lightBlue};
  }
`;

const Count = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
  color: ${cssVars.blue};
`;

const CardTitle = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  grid-area: 1 / 1 / 3 / 2;
  border-bottom: 1px solid ${cssVars.black};
  padding: 6px 4px;
  width: 75%;
`;

const CountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.7rem;
  align-items: center;
  color: ${cssVars.grey};
`;

function CountBody(props: { docs: string; savings: string }) {
  return (
    <FlexBox
      justifyContent={'space-between'}
      alignItems={'center'}
      // width={'80%'}
      css={css`
        padding: 4px;
      `}
    >
      <CountWrapper>
        <Count>{props.savings}</Count>
        <span>
          <FormattedMessage defaultMessage={'Total Savings'} description={'Total value of all savings'} />
        </span>
      </CountWrapper>
      <CountWrapper>
        <Count>{props.docs} </Count>
        <span>
          <FormattedMessage defaultMessage="Documents" description="Account page documents header" />
        </span>
      </CountWrapper>
    </FlexBox>
  );
}

function AccountCard(props: { account: Account; intl: IntlShape; selectedCurrency: ICurrency }) {
  return (
    <Link href={`/account/${props.account.id}`}>
      <Card
      // onClick={() => {
      //   router.push(`/account/${props.account.id}`);
      // }}
      >
        <CardTitle>{props.account.name}</CardTitle>
        <CountBody
          docs={props.account.totalCount}
          savings={props.intl.formatNumber(Number(props.account.savingsTotal), { style: 'currency', currency: props.selectedCurrency?.id })}
        />
      </Card>
    </Link>
  );
}

function UserCard(props: { user; intl: IntlShape; selectedCurrency: ICurrency }) {
  const data = props.user;
  const user = data.author.hits.hits[0]._source.author;
  return (
    <Link href={`/user/${user.id}`}>
      <Card>
        <CardTitle>
          {user.givenName} {user.familyName}
        </CardTitle>
        {/*<div>{user.email}</div>*/}
        <CountBody docs={data.doc_count} savings={props.intl.formatNumber(Number(data.totalSavings.value), { style: 'currency', currency: props.selectedCurrency?.id })} />
      </Card>
    </Link>
  );
}

function CompanyLeaderboard(props: CompanyLeaderboardProps) {
  const [topAccounts, setTopAccounts] = useState<Array<Account>>([]);
  const [topUsers, setTopUsers] = useState([]);
  const [topBranches, setTopBranches] = useState([]);
  const intl = useIntl();
  const { selectedCurrency } = useContext(CurrencyContext);

  useQuery(['get_company_admin_rankings', props.filterRange], () => CompanyClient.getRankings({ startDate: props.filterRange.start, endDate: props.filterRange.end }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    // enabled: isAdmin && isAdminChecked,
    onSuccess: (d) => {
      setTopAccounts(d.payload.topAccounts);
      setTopUsers(d.payload.topUsers);
      setTopBranches(d.payload.topBranches);
    },
  });

  const rankingCategories = [
    {
      id: 'topUsers',
      icon: 'users' as IconName,
      label: <FormattedMessage defaultMessage={'Top Users'} description={'Label for table of users who have created the most savings'} />,
      records: topUsers,
      render: (u) => <UserCard key={`topUser${u.author.hits.hits[0]._source.author.email}`} user={u} intl={intl} selectedCurrency={selectedCurrency} />,
    },
    {
      id: 'topAccounts',
      icon: 'chart-line' as IconName,
      label: <FormattedMessage defaultMessage={'Top Accounts'} description={'Label for table of accounts with most created savings'} />,
      records: topAccounts,
      render: (a) => <AccountCard key={`topAccount${a.id}`} account={a} intl={intl} selectedCurrency={selectedCurrency} />,
    },
    {
      id: 'topBranches',
      icon: 'building' as IconName,
      label: <FormattedMessage defaultMessage={'Top Branches'} description={'Label for table of branches with most created savings'} />,
      records: topBranches,
      render: (b) => <AccountCard key={`topBranch${b.id}`} account={b} intl={intl} selectedCurrency={selectedCurrency} />,
    },
  ];

  return (
    <StyledCompanyLeaderboard>
      <DashboardList categories={rankingCategories} setShowTags={null} />
    </StyledCompanyLeaderboard>
  );
}

export default CompanyLeaderboard;

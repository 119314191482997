/** @format */

import { useIsAdmin } from '@common/application/hooks/useIsAdmin';
import AccountQuickNav from '@organisms/Dashboard/AccountQuickNav';
import DocumentQuickNav from '@organisms/Dashboard/DocumentQuickNav';
import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import AdminDashboard from './AdminDashboard';

interface UserDashboardProps {}

const StyledUserDashboard = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  //grid-template-columns: repeat(2, 50%);
  //grid-auto-rows: 1fr 125px;
  padding: 8px 8px 8px 4px;
  box-sizing: border-box;
  gap: 8px;
`;

const NavSection = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  overflow: auto;
`;

const NavWrapper = styled.div`
  width: 50%;
  height: 100%;
`;

function UserDashboard(props: UserDashboardProps) {
  const { isAdmin } = useIsAdmin();
  return (
    <StyledUserDashboard>
      <NavSection>
        <NavWrapper>
          <AccountQuickNav />
        </NavWrapper>
        <NavWrapper>
          <DocumentQuickNav />
        </NavWrapper>
      </NavSection>
      {isAdmin && <AdminDashboard />}
    </StyledUserDashboard>
  );
}

export default UserDashboard;

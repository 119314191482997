/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import Link from '@atoms/Link';
import styled from '@emotion/styled';
import chroma from 'chroma-js';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';

const CountUp = dynamic(() => import('react-countup'), { ssr: false });

interface AdminDashboardItemProps {
  color: string;
  quantity: number;
  label: string;
  href: string;
}

const StyledAdminDashboardItem = styled.div<{ color: string }>`
  background: ${(props) => props.color}88;
  height: 120px;
  width: clamp(120px, auto, 200px);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, ${(props) => chroma(props.color).darken(0.8).hex()} 0%, ${(props) => props.color} 100%);
  color: ${cssVars.white};
  padding: 4px;
  cursor: pointer;
  transition: transform 0.5s ease;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  :hover {
    transform: scale(1.02);
  }
`;

const Quantity = styled.span<{ color: string }>`
  font-size: 48px;
  font-weight: bold;
  border-radius: 3px;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  flex-basis: 100%;
  width: 100%;
`;

const Label = styled.span`
  font-size: 0.8rem;
  text-align: center;
  height: 45%;
  display: grid;
  place-items: center;
  width: 120px;
`;

function AdminDashboardItem(props: AdminDashboardItemProps) {
  const router = useRouter();
  const lastQtyRef = useRef(0);

  return (
    <Link href={props.href}>
      <StyledAdminDashboardItem color={props.color}>
        <Quantity color={props.color}>
          <CountUp
            duration={2}
            useEasing={true}
            start={lastQtyRef.current}
            end={props.quantity}
            onEnd={() => {
              lastQtyRef.current = props.quantity;
            }}
            preserveValue={true}
          />
        </Quantity>
        <Label>{props.label}</Label>
      </StyledAdminDashboardItem>
    </Link>
  );
}

export default AdminDashboardItem;

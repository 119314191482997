/** @format */

import Scrollbar from '@atoms/Scrollbar';
import DashboardDocListItem from '@organisms/Dashboard/DashboardDocListItem';
import React from 'react';
import styled from '@emotion/styled';
import { cssVars } from '@atoms/GlobalStyles/GlobalStyles';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/react';
import { DocumentClient } from '@common/model/apiClient';

interface RecentDocsProps {
  docs: Array<DocumentClient>;
}

const StyledRecentDocs = styled.div`
  flex: 1;
  background: ${cssVars.white};
  border-radius: 3px;
  flex-direction: column;
  display: flex;
  width: 50%;
  //overflow: hidden;
  height: 100%;
  > div:first-of-type {
    background: ${cssVars.charcoal};
    color: ${cssVars.white};
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-radius: 3px 3px 0 0;
    height: 40px;
    //display: grid;
    //place-items: center;
  }
`;

const RecentDocsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  box-sizing: border-box;
  position: relative;
  flex: 1;
  height: 0;
`;

function RecentDocs({ docs }: RecentDocsProps) {
  return (
    <StyledRecentDocs>
      <div>
        <span>
          <FormattedMessage defaultMessage={'Most Recent Documents'} description={'List of most recently created documents'} />
        </span>
      </div>
      <div
        css={css`
          flex: 1;
          //overflow: hidden;
        `}
      >
        <Scrollbar>
          <RecentDocsWrapper>
            {docs.map((doc) => (
              <DashboardDocListItem key={`Document${doc.id}`} doc={doc} />
            ))}
          </RecentDocsWrapper>
        </Scrollbar>
      </div>
    </StyledRecentDocs>
  );
}

export default RecentDocs;

/** @format */

import { TagCategory } from '@common/model/apiClient/TagCategory';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';

export default function useInfiniteScrollSearch([items, setItems], options: { size: number; queryKey: string }) {
  const [search, _setSearch] = useState('');
  const [searchAfter, setSearchAfter] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const queryClient = useQueryClient();

  const setSearch = (v) => {
    _setSearch(v);
    setSearchAfter(null);
  };

  const fetchNext = useCallback(() => {
    if (items && !!items.length) {
      const lastId = items[items.length - 1].id;
      setSearchAfter(lastId);
    }
  }, [items]);

  const refetch = () => {
    setSearch('');
    queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes(options.queryKey) });
  };

  const updateResults = (data: Array<{ id: number }>) => {
    // const mappedData = data.map((d) => d._source);
    setItems((items) => (!searchAfter ? data : [...items.filter((result) => !data.map((d) => d.id).includes(result.id)), ...data]));
    if (data.length < options.size) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  return { search, setSearch, fetchNext, updateResults, hasMore, searchAfter, refetch };
}

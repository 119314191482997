/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import Link from '@atoms/Link';
import Text from '@atoms/Text';
import { CurrencyContext } from '@common/application/context/CurrencyProvider';
import { Account } from '@common/model/apiClient/Account';
import { css } from '@emotion/react';
import { ListItem } from '@organisms/Dashboard/DashboardAccountsListItem';
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { DocumentClient } from '@common/model/apiClient';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouter } from 'next/router';

interface DashboardDocListItemProps {
  doc: DocumentClient & { accountName?: string };
}

const Title = styled.span`
  color: ${cssVars.black};
  font-weight: bold;
  font-size: 1.25rem;
  padding-block: 2px;
  > span {
    font-weight: normal;
    font-size: 0.8rem;
    color: ${cssVars.silverGrey};
    margin-inline: 4px;
  }
`;

const Author = styled.span`
  color: ${cssVars.silverGrey};
  font-size: 14px;
  padding-block: 2px;
`;

const Total = styled.span`
  color: ${cssVars.black};
  font-size: 1rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
`;

const Description = styled.span`
  color: ${cssVars.silverGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  //flex: 0.5;
  //max-width: calc(100% - 80px);
`;

const AccountName = styled.span`
  color: ${cssVars.black};
  //font-weight: bold;
`;

function DashboardDocListItem({ doc }: DashboardDocListItemProps) {
  const { selectedCurrency } = useContext(CurrencyContext);
  const intl = useIntl();
  return (
    <Link href={`/account/${doc.accountId}/document/${doc.id}`}>
      <ListItem>
        <Header>
          <Title>
            {doc.title}
            <span>({doc.id})</span>
          </Title>
          {(doc.accountName || doc.account.name) && <AccountName>{doc.accountName ?? doc.account.name}</AccountName>}
        </Header>
        {doc.savingsTotal && <Total>{intl.formatNumber(Number(doc.savingsTotal), { style: 'currency', currency: selectedCurrency?.id })}</Total>}
        <Description>{JSON.parse(doc?.description ?? null)?.blocks?.[0]?.text}</Description>
        {doc.author && (
          <Author>
            {doc.author?.givenName} {doc.author?.familyName}
          </Author>
        )}
      </ListItem>
    </Link>
  );
}

export default DashboardDocListItem;

/** @format */

import { RecordStatus } from '@common/application/enums';
import convertFileToB64 from '@common/application/helpers/convertFileToB64';
import { TagCategory } from '@common/model/apiClient/TagCategory';
import axios from 'axios';

export class Tag {
  id: number;
  label: string;
  categoryId: number;
  category?: TagCategory;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  status?: RecordStatus;

  constructor(categoryId: number, label: string) {
    this.categoryId = categoryId;
    this.label = label;
  }

  static list(
    entityType,
    query,
    queryOpts: Record<string, unknown>,
    size: number,
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<Tag>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/tags`, {
          params: {
            sequence_id: 1,
            query,
            queryOpts: JSON.stringify(queryOpts),
            // sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
            entityType,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static update(tag: Tag) {
    return axios
      .patch(`${window.location.protocol}//${window.location.host}/api/v1/tags/${tag.id}`, {
        payload: {
          ...tag,
        },
      })
      .then((res) => ({ ...res.data }));
  }

  static delete(tagId: number) {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/tags/${tagId}`, {}).then((res) => ({ ...res.data }));
  }

  static undelete(tagId: number) {
    return axios
      .patch(`${window.location.protocol}//${window.location.host}/api/v1/tags/${tagId}`, {
        payload: { deletedAt: null },
      })
      .then((res) => ({ ...res.data }));
  }
  create() {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/tags`, {
        payload: {
          ...this,
        },
      })
      .then((res) => ({ ...res.data }));
  }
}

/** @format */

import useSecureQuery from '@common/application/auth/useSecureQuery';
import useLocalStorage from '@common/application/hooks/useLocalStorage/useLocalStorage';
import useInfiniteScrollSearch from '@common/application/hooks/useTagSearch/useInfiniteScrollSearch';
import { AccountClient } from '@common/model/apiClient';
import { Account } from '@common/model/apiClient/Account';
import { Tag } from '@common/model/apiClient/Tag';
import { User } from '@common/model/apiClient/User';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import DashboardAccountsListItem from '@organisms/Dashboard/DashboardAccountsListItem';
import QuickNav from '@organisms/Dashboard/QuickNav';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

interface AccountQuickNavProps {}

function AccountQuickNav(props: AccountQuickNavProps) {
  const [accountTags, setAccountTags] = useState([]);
  const [documentTags, setDocumentTags] = useState([]);
  const [accountHistory, setAccountHistory] = useState([]);
  const [favouriteAccounts, setFavouriteAccounts] = useState<Account[]>([]);
  const [selectedAccountTags, setSelectedAccountTags] = useLocalStorage('account-account-tags', []);
  const [selectedDocumentTags, setSelectedDocumentTags] = useLocalStorage('account-document-tags', []);
  const [taggedAccounts, setTaggedAccounts] = useState([]);

  const session = useSecureSession<BaseRealmRole, BaseRole>();

  const { isFetching: accountHistoryFetching } = useQuery([session?.data?.user?.id, 'user_history'], () => User.getHistory(session?.data?.user?.id), {
    onSuccess: (data) => {
      const { payload } = data.data;
      setAccountHistory(payload.accounts);
      // setDocumentHistory(payload.documents);
    },
  });

  const { data, isFetching: isTaggedAccountsFetching } = useSecureQuery(
    ['accounts_home_search', selectedAccountTags, selectedDocumentTags],
    // () => AccountClient.list('', {}, { deleted: false, musts: selectedAccountTags.map((t) => ({ terms: { tags: [`${t.id}`] } })) }, 10),
    () =>
      AccountClient.getByTag(
        selectedAccountTags.map((t) => t.id),
        selectedDocumentTags.map((t) => t.id),
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      keepPreviousData: true,
      enabled: !!selectedAccountTags.length || !!selectedDocumentTags.length,
      onSuccess: ({ data }) => {
        setTaggedAccounts(data.payload);
      },
    },
  );

  const { refetch: refetchFavourites, isFetching: favouritesFetching } = useQuery(
    [session?.data?.user?.id, 'fav_accounts'],
    () => User.getFavouriteAccounts(session?.data?.user?.id),
    {
      onSuccess: (d) => {
        // console.log(d);
        setFavouriteAccounts(d.data?.payload?.favourites);
      },
    },
  );

  const searchSize = 10;
  const {
    search: accountTagSearch,
    setSearch: setAccountTagSearch,
    fetchNext: fetchNextAccountTags,
    updateResults: updateAccountTags,
    hasMore: hasMoreAccountTags,
    searchAfter: accountTagSearchAfter,
    refetch: refetchAccountTags,
  } = useInfiniteScrollSearch([accountTags, setAccountTags], { size: searchSize, queryKey: 'account_tag_query' });
  const {
    search: documentTagSearch,
    setSearch: setDocumentTagSearch,
    fetchNext: fetchNextDocumentTags,
    updateResults: updateDocumentTags,
    hasMore: hasMoreDocumentTags,
    searchAfter: documentTagSearchAfter,
    refetch: refetchDocumentTags,
  } = useInfiniteScrollSearch([documentTags, setDocumentTags], { size: searchSize, queryKey: 'accounts_document_tag_query' });

  useSecureQuery(['account_tag_query', accountTagSearch, accountTagSearchAfter], () => Tag.list('account', accountTagSearch, {}, searchSize, accountTagSearchAfter), {
    onSuccess: (data) => {
      updateAccountTags(data.payload);
    },
  });

  useSecureQuery(['accounts_document_tag_query', documentTagSearch, documentTagSearchAfter], () => Tag.list('document', documentTagSearch, {}, 10, documentTagSearchAfter), {
    onSuccess: (data) => {
      updateDocumentTags(data.payload);
    },
  });

  const accountCategories = useMemo(
    () => [
      {
        icon: 'star' as IconName,
        label: <FormattedMessage defaultMessage={'Favourite Accounts'} description={'Accounts that have been marked as Favourite by user'} />,
        records: favouriteAccounts,
        render: (r: Account) => <DashboardAccountsListItem account={r} />,
        id: 'fav',
        loading: favouritesFetching,
      },
      {
        icon: 'eye' as IconName,
        label: <FormattedMessage defaultMessage={'Recently Viewed Accounts'} description={'Recently Visited Accounts'} />,
        records: accountHistory,
        id: 'recent',
        render: (r: Account) => <DashboardAccountsListItem account={r} />,
        loading: accountHistoryFetching,
      },
      {
        icon: 'tag' as IconName,
        label: <FormattedMessage defaultMessage={'Tagged Accounts'} />,
        records: !!selectedAccountTags.length || !!selectedDocumentTags.length ? taggedAccounts : [],
        id: 'tag',
        render: (r: Account) => <DashboardAccountsListItem account={r} />,
        loading: isTaggedAccountsFetching,
      },
    ],
    [favouriteAccounts, favouritesFetching, accountHistory, accountHistoryFetching, selectedAccountTags.length, taggedAccounts, isTaggedAccountsFetching],
  );

  const tagCategories = [
    {
      label: <FormattedMessage description={'Administration side menu accounts link'} defaultMessage={'Accounts'} />,
      allTagsFetched: !hasMoreAccountTags,
      setSelectedTags: setSelectedAccountTags,
      selectedTags: selectedAccountTags,
      fetchNextTags: fetchNextAccountTags,
      setTagSearch: setAccountTagSearch,
      tagOptions: accountTags,
      tagSearch: accountTagSearch,
      id: 'accountQuickNavAccountTags',
    },
    {
      label: <FormattedMessage defaultMessage={'Documents'} description={'Help documents header'} />,
      allTagsFetched: !hasMoreDocumentTags,
      setSelectedTags: setSelectedDocumentTags,
      selectedTags: selectedDocumentTags,
      fetchNextTags: fetchNextDocumentTags,
      setTagSearch: setDocumentTagSearch,
      tagOptions: documentTags,
      tagSearch: documentTagSearch,
      id: 'accountQuickNavDocumentTags',
    },
  ];

  return <QuickNav tagCategories={tagCategories} categories={accountCategories} />;
}

export default AccountQuickNav;

/** @format */

import Dashboard from '@organisms/Dashboard/Dashboard';
import React, { useState } from 'react';

export function Index() {
  const [whatsNewShown, _setWhatsNewShown] = useState(!!JSON.parse(sessionStorage.getItem('popupShown')));

  const setWhatsNewShown = (val: boolean) => {
    sessionStorage.setItem('popupShown', JSON.stringify(!val));
    _setWhatsNewShown(!val);
  };

  const stopDate = new Date('2024-11-01').getTime();
  const now = Date.now();

  return (
    // <Scrollbar>
    //   <ContentWrapper layoutId="page-home">
    //     {/*{now < stopDate && <WhatsNewModal isShown={whatsNewShown} setIsShown={setWhatsNewShown} />}*/}
    //     <AccountsTable />
    //     <VSpacer />
    //   </ContentWrapper>
    // </Scrollbar>
    <Dashboard />
  );
}

export default Index;

/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import Link from '@atoms/Link';
import { Account } from '@common/model/apiClient/Account';
import styled from '@emotion/styled';
import React from 'react';
import Text from '@atoms/Text';
import { css } from '@emotion/react';
import { useRouter } from 'next/router';

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid ${cssVars.lightGrey};
  //width: 100%;
  padding: 8px;
  cursor: pointer;
  max-width: 100%;
  :hover {
    background: ${cssVars.lightBlue};
  }
`;

const Name = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 4px;
`;

function DashboardAccountListItem(props: { account: Account }) {
  const router = useRouter();
  return (
    <Link href={`account/${props.account.id}`}>
      <ListItem>
        <Name>{props.account.name}</Name>
        <span>{props.account.accountNumber}</span>
      </ListItem>
    </Link>
  );
}

export default DashboardAccountListItem;
